// Chakra imports
import { Flex, Text, Badge, Center, Spinner } from "@chakra-ui/react";
// Custom components
import React from "react";
// Assets
import Card from "../../../card/Card";
import Content from "./Content";
import { format, parseISO, isAfter } from "date-fns";
import ResponseContent from "./ResponseContent";

export default function ResponseDetails(props) {
  const {
    response,
    bgButton,
    bgFocus,
    bgHover,
    schema,
    formData,
    isLoading,
    ...rest
  } = props;
  return (
    <Card {...rest} justifySelf="center" alignSelf="center" my="0px" p="30px">
      {!isLoading ? (
        <ResponseContent
          schema={schema}
          formData={formData}
          response={response}
        />
      ) : (
        <Flex justifyContent={"center"} alignItems="center">
          <Spinner />
        </Flex>
      )}
    </Card>
  );
}

import React, { useEffect, useState, useRef } from "react";
import {
  Text,
  Box,
  Heading,
  Flex,
  VStack,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link,
  Grid,
  SimpleGrid,
  Icon,
  FormLabel,
  useDisclosure,
  Badge,
  FormControl,
  Button,
  Spinner,
} from "@chakra-ui/react";

import { useAuth } from "../context/auth";
import {
  getDropzone,
  getDropzoneResponsesWithDropzoneKey,
  getDropzoneResponsesWithDropzoneKeyByLastEvaluatedResponse,
  getResponse
} from "@defense-station/api-service";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../components/card/Card";
import Documentation from "../components/secure-share/Documentation";
import { VSeparator } from "../components/separator/Separator";
import * as timeago from "timeago.js";
import {
  addDays,
  endOfDay,
  format,
  isAfter,
  parseISO,
  startOfDay,
} from "date-fns";
import { GoPrimitiveDot } from "react-icons/go";
import { BiCircle } from "react-icons/bi";
import SwitchField from "../components/fields/SwitchField";
import {
  SERVICE_BASE_PATH_FOR_,
  SERVICE_BASE_PATH_FOR_DROPZONE,
  SERVICE_BASE_PATH_FOR_DROPZONEDROPZONE,
} from "../constants/constants";
import InformationRow from "../components/InformationRow";
import InputField from "../components/fields/InputField";
import NumberField from "../components/fields/NumberField";
import { updateRequestedDataSchema } from "../validations/request-data-schema";
import { Formik } from "formik";
import toast, { showServerError } from "../components/toast";
import Details from "../components/secure-share/dropzone/details/Details";
import UpdateForm from "../components/secure-share/dropzone/details/UpdateForm";
import banner from "../assets/img/auth/banner.png";
import avatar from "../assets/img/avatars/avatar4.png";
import Banner from "../components/secure-share/dropzone/details/Banner";
import AllowedOrigins from "../components/secure-share/dropzone/details/AllowedOrigins";
import DropzonesResponsesTable from "../components/secure-share/dropzone/DropzoneResponsesTable";
import CardHeaderText from "../components/custom-styled-components/CardHeaderText";
import { toast as toastify } from "react-toastify";
import JSZip from "jszip";
import { delay, generateCSV, readAndDecryptFileBlobFromURL, readAndDecryptFileContentFromURL } from "../utils/custom-function";

const DropzoneDetails = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);
  const [responsesLoading, setResponsesLoading] = useState(true);
  const [linkBroken, setLinkBroke] = useState(false);
  const [responses, setResponses] = useState([]);
  const [next, setNext] = useState(null);
  const baseDate = new Date();
  const [range, setRange] = useState([
    {
      start_date: startOfDay(addDays(baseDate, -30)),
      end_date: endOfDay(baseDate),
      startDate: startOfDay(addDays(baseDate, -30)),
      endDate: endOfDay(baseDate),
      key: "selection",
    },
  ]);
  const [isExporting, setExporting] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const toastRef = useRef();
  const buildLastEvaluatedPostObject = (last_evaluated_post) => {
    let LastEvalutedPost = null;
    if (last_evaluated_post?.sk) {
      LastEvalutedPost = { ...last_evaluated_post };
      delete LastEvalutedPost.__typename;
    }
    return LastEvalutedPost;
  };
  const initFormData = {
    retry_limit: 5,
    hide_stats: true,
    login_required: false,
    enableNotification: true,
    responder_controls: false,
    hide_identity: false,
    label: "",
  };
  const [formData, setFormData] = useState(initFormData);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getDropzone(
        user?.account_id,
        user?.username,
        searchParams.get("id")
      );
      if (res.errors) {
        const error = res?.errors[0]?.message;
        const code = error.split(" ")[0];
        if (code == 7) {
        }
        const errors = res.errors;
        errors.map((error) => {
          if (error.message.includes("access denied")) setLinkBroke(true);
        });
      }
      let data = res?.data?.sypher_v1_GetDropzone?.dropzone;
      setFormData({
        retry_limit: data?.access_limit,
        hide_stats: data?.hide_stats,
        login_required: data?.login_required,
        responder_controls: data?.responder_controls,
        hide_identity: data?.hide_identity,
        label: data?.label,
      });
      setData(data);
      setLoading(false);
    } catch (e) {
      if (e.message.includes("access denied")) {
        setLinkBroke(true);
      } else {
        showServerError(e);
      }
      throw e;
    }
  };

  const fetchResponses = async () => {
    await getDropzoneResponsesWithDropzoneKey(
      params?.dropzone_name,
      user.account_id,
      user.username,
      range[0]?.start_date,
      range[0]?.end_date
    )
      .then((response) => {
        setResponses(
          response?.data?.sypher_v1_ListDropZoneResponses?.responses
        );
        const lastEvaluatedPost = buildLastEvaluatedPostObject(
          response?.data?.sypher_v1_ListDropZoneResponses
            ?.last_evaluated_response
        );
        setNext(lastEvaluatedPost?.sk?lastEvaluatedPost:undefined);
      })
      .catch((e) => { });
    setResponsesLoading(false);
    setRefreshing(false);
  };

  useEffect(() => {
    if (!params?.dropzone_name && !searchParams.get("id")) {
      setLinkBroke(true);
      setLoading(false);
      return true;
    }
    if (user) {
      fetchData();
      fetchResponses();
    }
  }, [user]);
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const textColorSecondary = "gray.400";
  const parsedEndDate = data ? parseISO(data?.end_date) : "";
  const parsedStartDate = data ? parseISO(data?.start_date) : "";
  const expired = data ? isAfter(new Date(), parsedEndDate) : "";
  const live = data
    ? isAfter(new Date(), parsedStartDate) && isAfter(parsedEndDate, new Date())
    : "";
  const idle = data
    ? isAfter(parsedStartDate, new Date()) && isAfter(parsedEndDate, new Date())
    : "";
  const retryLimit = data?.access_limit ? data?.access_limit : 0;
  let selectedColor = null;
  let icon = null;
  let colorScheme = null;
  let status = null;
  if (data?.responder_controls) {
    selectedColor = "brand.600";
    icon = GoPrimitiveDot;
    colorScheme = "brand";
    status = "Live";
  } else {
    if (expired) {
      selectedColor = "red.400";
      icon = GoPrimitiveDot;
      colorScheme = "red";
      status = "Expired";
    } else if (live) {
      selectedColor = "brand.600";
      icon = GoPrimitiveDot;
      colorScheme = "brand";
      status = "Live";
    } else if (idle) {
      selectedColor = "brand.600";
      icon = BiCircle;
      colorScheme = "gray";
      status = "Idle";
    }
  }

  const onViewResponse = () => {
    navigate(`/dropzones/history/${data?.dropzone_name}/responses`);
  };

  const _onSubmit = async (values, { resetForm }) => {
    try {
      const { updateRequest } = await import("@defense-station/api-service");
      const data = await updateRequest({
        retry_limit: values?.access_limit,
        hide_stats: values?.hide_stats,
        login_required: values?.login_required,
        responder_controls: values?.responder_controls,
        hide_identity: values?.hide_identity,
        label: values?.label,
      });
      setData({ ...data, ...values });
      toast({
        title: "Request sent",
        description: "Data has been requested.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (e) {
      if (e.response) {
        toast({
          title: "Response Error",
          description: e?.response?.errors[0]?.message,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      toast({
        title: "Application Error",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const bgButton = "rgba(255,255,255,0.12)";
  const bgHover = { bg: "whiteAlpha.50" };
  const bgFocus = { bg: "rgba(255,255,255,0.12)" };

  const fetchNext = () => {
    setIsLazyLoading(true);
    getDropzoneResponsesWithDropzoneKeyByLastEvaluatedResponse(
      params?.dropzone_name,
      user.account_id,
      user.username,
      range[0]?.start_date,
      range[0]?.end_date,
      next
    ).then((response) => {
      let nextData = response?.data?.sypher_v1_ListDropZoneResponses?.responses;
      console.log({ nextData, data })
      if (nextData) setResponses([...responses, ...nextData]);
      const lastEvaluatedPost = buildLastEvaluatedPostObject(
        response?.data?.sypher_v1_ListDropZoneResponses?.last_evaluated_response
      );
      setNext(lastEvaluatedPost?.sk?lastEvaluatedPost:undefined);
      setIsLazyLoading(false);
    });
  };

  const onApply = (range) => {
    setRange(range);
  };
  const onRefresh = () => {
    setRefreshing(true);
    fetchResponses();
  };
  const onUpdateForm = (dropzone) => setData(dropzone);

  const onDownloadProgress = (data) => {
    toastify.update(toastRef.current, {
      progress: data,
    })
  }


  const getResonseData = async (response) => {
    try {
      const res = await getResponse(
        user?.account_id,
        user?.username,
        response.requester_id,
        response.respondent_id
      );
      if (res.errors) {
        const error = res?.errors[0]?.message;
        const code = error?.split(" ")?.[0];
        if (code == 7) {
        }
        const errors = res.errors;
        errors.map((error) => {
          if (error.message.includes("access denied")) return;
        });
      }
      let data = res?.data?.sypher_v1_GetResponse?.response;
      const signedURLs = res?.data?.sypher_v1_GetResponse?.signed_urls;
      if (data?.template) {
        const prvKey = data?.enc_dec_key;
        const template = data?.template;
        const formSchema = JSON.parse(
          String.fromCharCode(...template?.data?.data)
        );
        const dataFile = signedURLs["data"];
        const decryptedDataFile = await readAndDecryptFileContentFromURL(
          dataFile,
          prvKey
        );
        const formData = JSON.parse(decryptedDataFile);
        const files = {};
        Object.keys(signedURLs)?.map((key) => {
          if (key != "data") {
            files[key] = {
              key: key,
              value: signedURLs[key],
            };
          }
        });
        return {
          decKey: prvKey,
          formData,
          files,
          formSchema
        }
      } else {
        return;
      }
    } catch (e) {
      if (e.message.includes("access denied")) {
        return;
      } else {
        showServerError(e);
        return;
      }
    }
  }


  const checkIfHasMoreResponses = async (oldResponses = responses, lastEvaluated = next) => {
    try {
      if(!lastEvaluated) return oldResponses;
      setIsLazyLoading(true);
      const response = await getDropzoneResponsesWithDropzoneKeyByLastEvaluatedResponse(
        params?.dropzone_name,
        user.account_id,
        user.username,
        range[0]?.start_date,
        range[0]?.end_date,
        lastEvaluated
      )
      let nextData = response?.data?.sypher_v1_ListDropZoneResponses?.responses;
      console.log({ nextData, data })
      if (nextData) setResponses([...oldResponses, ...nextData]);
      const lastEvaluatedPost = buildLastEvaluatedPostObject(
        response?.data?.sypher_v1_ListDropZoneResponses?.last_evaluated_response
      );
      setNext(lastEvaluatedPost?.sk?lastEvaluatedPost:undefined);
      let newResponses = [...oldResponses]
      if (lastEvaluatedPost?.sk) {
        await delay(500);
        newResponses = await checkIfHasMoreResponses([...oldResponses, ...nextData], lastEvaluatedPost);
      }
      setIsLazyLoading(false);
      return newResponses;
    }
    catch(e) {
      showServerError(e)
    }
  }

  const onExport = async () => {
    try {
      toastRef.current = toastify("Exporting Data", {
        progress: 0.1,
        type: "info"
      })
      setExporting(true)
      const responses = await checkIfHasMoreResponses();
      await delay(500);
      const zip = new JSZip();
      const filesFolder = zip.folder("files");
      const csvDataArray = [
        
      ]
      let headerArray = undefined;
      for (let index = 0; index < responses.length; index++) {
        const response = responses[index];
        toastify.update(toastRef.current, {
          render: `Downloading responses(${index + 1}/${responses?.length}).`,
          progress: ((index) / responses?.length).toFixed(2),
        })
        const responseData = await getResonseData(response);
        if (!responseData) continue;
        const { decKey, files, formData, formSchema } = responseData;
        if(!headerArray) {
          headerArray = [...Object.keys(formSchema?.properties)?.map(key => key)]
          const csvHeader = ["ID", ...headerArray.map(key => formSchema.properties[key]?formSchema.properties[key]?.title:key)]
          csvDataArray.push(csvHeader)
        }
        // toastify.update(toastRef.current, {
        //   render: "Downloading form csvDataArray.",
        //   isLoading: true,
        // })
        const downloadableFiles = Object.keys(formSchema?.properties)?.map(key => ({ ...formSchema.properties[key], key })).filter(field => field?.type == "string" && field?.format == "file");
        const row = headerArray.map(key => formData?.[key]?formData?.[key]:"")
        csvDataArray.push(row.length?[response?.response_id, ...row]:[response?.response_id, ])
        
        // const imgFolder = responseFolder.folder("files");
        if(downloadableFiles.length){
          const responseFolder = filesFolder.folder(response?.response_id);
          const imgFolder = responseFolder;
          for (let index = 0; index < downloadableFiles.length; index++) {
            // toastify.update(toastRef.current, {
            //   render: `Downloading files(${index}/${downloadableFiles?.length}).`,
            //   isLoading: true,
            //   progress: 0,
            // })
            const element = downloadableFiles[index];
            const decryptBlob = await readAndDecryptFileBlobFromURL(
              files[element?.key]?.value,
              decKey,
              onDownloadProgress,
              element?.key,
              controller.signal
            );
            imgFolder.file(formData[element?.key], decryptBlob);
          }
        }
        toastify.update(toastRef.current, {
          render: `Downloading responses(${index}/${responses?.length}).`,
          progress: ((index + 1) / responses?.length).toFixed(2),
        })
      }
      if(csvDataArray.length > 1) {
        const csvData = await generateCSV(csvDataArray, "export")
        zip.file("Export.csv", csvData);
      }
      console.log("Creating zip")
      toastify.update(toastRef.current, {
        render: `Creating zip file.`,
        progress: 1
      })
      const blob = await zip.generateAsync({ type: "blob" })
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
      toastify.update(toastRef.current, {
        render: `Data Exported`,
        isLoading: false,
        autoClose: 1000,
        type: "success"
      })
      toastRef.current = null;
      // Create a temporary <a> element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = `${data?.dropzone_name}.zip`;
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up by revoking the URL
      window.URL.revokeObjectURL(url);
    }
    catch (e) {
      // showServerError(e)
      console.log(e)
      toastify.update(toastRef.current, {
        render: e?.message ? e?.message : "Something went wrong. Please try after sometime.",
        isLoading: false,
        autoClose: 1000,
        type: "error"
      })
    }
    finally {
      setExporting(false)
    }
  }

  return linkBroken ? (
    <Flex h={"100vh"} justifyContent={"center"} alignItems="center">
      <VStack>
        <Heading>Link is Broken.</Heading>
      </VStack>
    </Flex>
  ) : (
    <>
      {!isLoading ? (
        <Box p="6" flex={1}>
          <Box mb={{ sm: "8px", md: "0px" }}>
            <Breadcrumb>
              <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
                <BreadcrumbLink href="/" color={secondaryText}>
                  Sypher
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
                <BreadcrumbLink
                  href={`${SERVICE_BASE_PATH_FOR_DROPZONE}history`}
                  color={secondaryText}
                >
                  History
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem color={secondaryText} fontSize="sm">
                <BreadcrumbLink
                  href={`${SERVICE_BASE_PATH_FOR_DROPZONE}history/${params?.uuid
                    }?email=${searchParams.get("email")}`}
                  color={secondaryText}
                >
                  {searchParams.get("email")}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            {/* <Link
                  color={mainText}
                  href="#"
                  bg="inherit"
                  borderRadius="inherit"
                  fontWeight="bold"
                  fontSize="34px"
                  _hover={{ color: { mainText } }}
                  _active={{
                    bg: "inherit",
                    transform: "none",
                    borderColor: "transparent",
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  {searchParams.get("email")}
                </Link> */}
          </Box>
          <Box>
            <Grid
              mb="20px"
              display={{ base: "block", xl: "grid" }}
              templateColumns={{
                base: "2.4fr 1fr",
                xl: "2fr 1fr",
                "2xl": "2.4fr 1fr",
              }}
              direction="column"
              gap={{ base: "20px", xl: "10px", "2xl": "20px" }}
            >
              <Box gridArea={{ base: "1 / 1 / 2 / 3", lg: "1 / 1 / 2 / 2" }}>
                <SimpleGrid spacing={{ base: "6", xl: "2", "2xl": "6" }}>
                  <Banner
                    banner={banner}
                    avatar={avatar}
                    link={`https://${window?.location?.host
                      }/sypher/dropzones/submit/${data?.dropzone_key}/${data?.password ? "yes" : "no"
                      }?id=${data?.dropzone_id}`}
                    name={data?.dropzone_name}
                    description={data?.description}
                    job={format(
                      parseISO(data?.created_at),
                      "MMM d, yyyy, h:mm a"
                    )}
                    startDate={timeago.format(data?.start_date)}
                    endDate={timeago.format(data?.end_date)}
                    responderControls={data?.responder_controls}
                    statusColor={colorScheme}
                    status={status}
                  />
                  {/* <AllowedOrigins allowedOrigins={data?.allowed_origins} /> */}
                  {responsesLoading ? (
                    <Flex justifyContent={"center"} alignItems="center">
                      <Spinner />
                    </Flex>
                  ) : (
                    <DropzonesResponsesTable
                      header={() => (
                        <Flex justify={"space-between"} align={"center"} ms="10px">
                          <Flex flexDir={"column"}>
                            <CardHeaderText
                              color={textColorPrimary}
                              fontWeight="bold"
                            >
                              Responses
                            </CardHeaderText>
                          </Flex>
                        </Flex>
                      )}
                      h={"30vh"}
                      hasNext={next}
                      isExporting={isExporting}
                      onExport={onExport}
                      fetchNext={fetchNext}
                      loading={isLazyLoading}
                      dropzones={responses}
                      onDateRangeApply={onApply}
                      onDateChange={setRange}
                      onRefresh={onRefresh}
                      refreshing={refreshing}
                    />
                  )}
                </SimpleGrid>
              </Box>

              <Box
                mt={{ base: "20px", xl: "0px" }}
                zIndex="0"
                gridArea={{ base: "2 / 1 / 3 / 3", lg: "1 / 2 / 2 / 3" }}
              >
                <UpdateForm dropzone={data} onSuccess={onUpdateForm} />
              </Box>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Flex h={"100vh"} justifyContent={"center"} alignItems="center">
          <Spinner />
        </Flex>
      )}
    </>
  );
};

export default DropzoneDetails;

import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Spinner,
  useBreakpointValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "../context/auth";
import {
  getDropzoneResponsesWithDropzoneKey,
  getDropzoneResponsesWithDropzoneKeyByLastEvaluatedResponse,
} from "@defense-station/api-service";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { RequestHistoryTable } from "../components/secure-share/request-history-table/RequestHistoryTable";
import { isPermissionError } from "../utils/custom-function";
import { SERVICE_BASE_PATH_FOR_DROPZONE } from "../constants/constants";
import DropzonesTable from "../components/secure-share/dropzone/DropzonesTable";
import { useParams } from "react-router-dom";
import DropzonesResponsesTable from "../components/secure-share/dropzone/DropzoneResponsesTable";
import { showServerError } from "../components/toast";

const DropzoneResponses = () => {
  const { user, setHasPermission } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const params = useParams();
  const [data, setData] = useState([]);
  const [next, setNext] = useState(null);
  const baseDate = new Date();
  const [range, setRange] = useState([
    {
      start_date: startOfDay(addDays(baseDate, -30)),
      end_date: endOfDay(baseDate),
      startDate: startOfDay(addDays(baseDate, -30)),
      endDate: endOfDay(baseDate),
      key: "selection",
    },
  ]);
  const buildLastEvaluatedPostObject = (last_evaluated_post) => {
    let LastEvalutedPost = null;
    if (last_evaluated_post?.sk) {
      LastEvalutedPost = { ...last_evaluated_post };
      delete LastEvalutedPost.__typename;
    }
    return LastEvalutedPost;
  };
  const fetchData = () => {
    getDropzoneResponsesWithDropzoneKey(
      params?.dropzone_name,
      user.account_id,
      user.username,
      range[0]?.start_date,
      range[0]?.end_date
    )
      .then((response) => {
        setData(response?.data?.sypher_v1_ListDropZoneResponses?.responses);
        const lastEvaluatedPost = buildLastEvaluatedPostObject(
          response?.data?.sypher_v1_ListDropZoneResponses
            ?.last_evaluated_response
        );
        setNext(lastEvaluatedPost);
        setIsLoading(false);
        setRefreshing(false);
      })
      .catch((e) => {
        if (isPermissionError(e?.message)) {
          setHasPermission(false);
        } else {
          showServerError(e);
        }
      });
  };
  useEffect(() => {
    if (user) {
      if (params?.dropzone_name) {
        fetchData();
      }
    }
  }, [user, range]);
  const fetchNext = () => {
    setIsLazyLoading(true);
    getDropzoneResponsesWithDropzoneKeyByLastEvaluatedResponse(
      params?.dropzone_name,
      user.account_id,
      user.username,
      range[0]?.start_date,
      range[0]?.end_date,
      next
    ).then((response) => {
      let nextData = response?.data?.sypher_v1_ListDropZoneResponses?.responses;
      if (nextData) setData([...data, ...nextData]);
      const lastEvaluatedPost = buildLastEvaluatedPostObject(
        response?.data?.sypher_v1_ListDropZoneResponses?.last_evaluated_response
      );
      setNext(lastEvaluatedPost);
      setIsLazyLoading(false);
    });
  };

  const onApply = (range) => {
    setRange(range);
  };
  const onRefresh = () => {
    setRefreshing(true);
    fetchData();
  };

  const popsize = useBreakpointValue({ base: null, lg: "auto" });
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  return (
    <>
      {isLoading ? (
        <Flex h={"100vh"} justifyContent={"center"} alignItems="center">
          <Spinner />
        </Flex>
      ) : (
        <Box p={6}>
          <Box mb={{ sm: "8px", md: "0px" }}>
            <Breadcrumb>
              <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
                <BreadcrumbLink href="/" color={secondaryText}>
                  Sypher
                </BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem color={secondaryText} fontSize="sm">
                <BreadcrumbLink
                  href={`${SERVICE_BASE_PATH_FOR_DROPZONE}history`}
                  color={secondaryText}
                >
                  History
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem color={secondaryText} fontSize="sm">
                <BreadcrumbLink
                  href={`${SERVICE_BASE_PATH_FOR_DROPZONE}history/${params?.dropzone_name}`}
                  color={secondaryText}
                >
                  {params?.dropzone_name}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem color={secondaryText} fontSize="sm">
                <BreadcrumbLink
                  href={`${SERVICE_BASE_PATH_FOR_DROPZONE}history/${params?.dropzone_name}/responses`}
                  color={secondaryText}
                >
                  Responses
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            {/* <Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              History
            </Link> */}
          </Box>

          <DropzonesResponsesTable
            hasNext={next}
            fetchNext={fetchNext}
            loading={isLazyLoading}
            dropzones={data}
            onDateRangeApply={onApply}
            onDateChange={setRange}
            onRefresh={onRefresh}
            refreshing={refreshing}
          />
        </Box>
      )}
    </>
  );
};

export default DropzoneResponses;

import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import FileView from "../fileview";
import { SymDecryptFile } from "../../utils/encrypt";
import { SUPPORTED_FILE_FOMATS } from "../../constants/constants";
import CustomText from "../custom-styled-components/CustomText";
import CustomProgressBar from "../custom-styled-components/CustomProgressBar";

export default function ViewFile(props) {
  const {
    name,
    url,
    isDownloading,
    isLoading,
    downloadAllowed,
    onView,
    onDownload,
    downloadingProgress,
    viewProgress,
    icon,
    ...rest
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const fileType = name.split(".").pop().toLowerCase();
  const textColor = useColorModeValue("brand.700", "white");
  const buttonSize = useBreakpointValue({ base: "sm", xl: "xs", "2xl": "sm" });
  return (
    <>
      <Flex
        borderWidth={1}
        p={2}
        borderRadius="lg"
        borderColor="secondaryGray.400"
        justifyContent={"space-between"}
      >
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          columnGap={3}
          rowGap={4}
          width={"full"}
        >
          <HStack>
            {icon}
            <CustomText color={textColor} fontWeight="700">
              {name}
            </CustomText>
          </HStack>
          <Button
            variant={isLoading ? "ghost" : "outline"}
            colorScheme={"brand"}
            px="24px"
            onClick={() => onView()}
            size={buttonSize}
            fontSize={{ base: "md", xl: "sm", "2xl": "md" }}
            mr="2"
            disabled={!SUPPORTED_FILE_FOMATS.includes(fileType) || isLoading}
            fontWeight="500"
          >
            {!isLoading ? (
              "View"
            ) : (
              <>
                Downloading...
                <CustomProgressBar
                  label={
                    viewProgress == 100 ? "Decrypting..." : `${viewProgress}%`
                  }
                  value={viewProgress}
                />
              </>
            )}
          </Button>
          {downloadAllowed && (
            <Button
              variant={isDownloading ? "ghost" : "solid"}
              colorScheme={"brand"}
              px="24px"
              size={buttonSize}
              onClick={() => onDownload()}
              fontSize={{ base: "md", xl: "sm", "2xl": "md" }}
              disabled={isDownloading}
              fontWeight="500"
            >
              {!isDownloading ? (
                "Download"
              ) : (
                <>
                  Downloading...
                  <CustomProgressBar
                    label={
                      downloadingProgress == 100
                        ? "Decrypting..."
                        : `${downloadingProgress}%`
                    }
                    value={downloadingProgress}
                  />
                </>
              )}
            </Button>
          )}
        </SimpleGrid>
      </Flex>
    </>
  );
}

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Grid,
  Icon,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import toast from "../components/toast";
import { SOMETHING_WENT_WRONG } from "../constants/constants";
import { getBackendStorage, createBackendStorage, updateBackendStorage } from "@defense-station/api-service";
import { useAuth } from "../context/auth";
import Card from "../components/card/Card";
import { Form, Formik } from "formik";
import CardHeaderText from "../components/custom-styled-components/CardHeaderText";
import CardDescriptionText from "../components/custom-styled-components/CardDescriptionText";
import InputField from "../components/fields/InputField";
import VALIDATION_SCHEMA from "../validations/create-backend-storage";
import SelectField from "../components/fields/SelectField";
import CustomButton from "../components/custom-styled-components/CustomButton";
import { TiCloudStorage } from "react-icons/ti";

const STORAGE_SERVICES = {
  AWS_S3: "AWS_S3",
  AZURE_BLOB: "AZURE_BLOB",
  GCP_STORAGE: "GCP_STORAGE",
};


const MODES = {
    EDIT: "EDIT",
    VIEW: "VIEW",
    CREATE: "CREATE"
}

const INITIAL_FORM_DATA = {
    storage_type: "",
    bucket_name: "",
    region: "",
    access_key: "",
    secret_key: "",
  }

export default function Storage() {
  let secondaryText = useColorModeValue("gray.700", "white");
  const { user } = useAuth();
  const [mode, setMode] = useState(MODES.CREATE);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [isInitialSetup, setInitialSetup] = useState(false)

  const fetchData = async () => {
    try {
      const data = await getBackendStorage(user?.account_id);
      const backendStorage = data?.data?.sypher_v1_GetBackendStorage?.backend_storage
      if(backendStorage?.access_key){
        setFormData(backendStorage)
        setMode(MODES.EDIT)
      }
      else {
        setInitialSetup(true)
      }
    } catch (e) {
      console.log(e);
      toast({
        description: SOMETHING_WENT_WRONG,
        status: "error",
      });
    }
    finally {
        setIsLoading(false);
    }
  };

  const onSubmit = async (values) => {
    try{
        if(isInitialSetup){
            const res = await createBackendStorage({
                account_id: user?.account_id,
                username: user?.username,
                storage_type: values.storage_type,
                bucket_name: values.bucket_name,
                region: values.region,
                access_key: values.access_key,
                secret_key: values.secret_key
            })
        }
        else{ 
            const res = await updateBackendStorage({
                account_id: user?.account_id,
                username: user?.username,
                storage_type: values.storage_type,
                bucket_name: values.bucket_name,
                region: values.region,
                access_key: values.access_key,
                secret_key: values.secret_key
            })
        }
        setInitialSetup(false)
        toast({
            description: "Backend configuration has been completed.",
            status: "success",
          })
    }catch (e) {
        console.log(e);
        toast({
            description: SOMETHING_WENT_WRONG,
            status: "error",
          });
    }
    finally {

    }
  }
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  return (
    isLoading ? (
        <Flex h={"100vh"} justifyContent={"center"} alignItems="center">
          <Spinner />
        </Flex>
      ) : mode == MODES.CREATE? 
      <Flex flexDir={"column"} h={"100vh"} justifyContent={"center"} alignItems="center">
            <Icon 
            as={TiCloudStorage}
            width={"100px"}
            height={"100px"}
            color="brand.500"
            />
            <Text fontWeight={"bold"} mb="20px">Backend is not Setup. Click on button to setup.</Text>
          <CustomButton onClick={() => setMode(MODES.EDIT)} colorScheme="brand">
            Create Backend
          </CustomButton>
        </Flex>
      :<Grid
      maxW="100%"
      gap="4"
      p={{ base: "6", xl: "6", "2xl": "6" }}
      display={"block"}
    >
      <Box gridArea="send" mb={{ base: "20px", "2xl": "0px" }}>
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb>
            <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
              <BreadcrumbLink href="/" color={secondaryText}>
                Sypher
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem color={secondaryText} fontSize="sm">
              <BreadcrumbLink href="settings" color={secondaryText}>
                Storage
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          {/* <Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              Send
            </Link> */}
          <Formik
            enableReinitialize
            validateOnChange={false}
            validationSchema={VALIDATION_SCHEMA}
            initialValues={formData}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              isSubmitting,
              setFieldValue,
              handleChange,
            }) => (
              <Form style={{ width: "100%" }}>
                <Stack spacing={"20px"}>
                  <Card flex={{ base: "1", lg: "2" }}>
                    <Flex
                      direction="column"
                      mb={{
                        base: "30px",
                        xl: "15px",
                        "2xl": "30px",
                      }}
                      ms="10px"
                    >
                      <CardHeaderText>Data</CardHeaderText>
                      <CardDescriptionText>
                        Enter information to be shared
                      </CardDescriptionText>
                    </Flex>
                    <SelectField
                      required
                      variant="main"
                      label="Storage Service"
                      name="storage_type"
                      onChange={handleChange}
                      error={errors.storage_type}
                      value={values.storage_type}
                      placeholder="Select Category"
                    >
                      {Object.keys(STORAGE_SERVICES).map((service) => (
                        <option key={service} value={service}>
                          {service}
                        </option>
                      ))}
                    </SelectField>
                    <InputField
                      id="bucket_name"
                      label="Bucket Name"
                      name="bucket_name"
                      required
                      value={values?.bucket_name}
                      onChange={handleChange}
                      error={errors?.bucket_name}
                      data-gramm="false"
                      placeholder="Enter bucket name"
                    />
                    <InputField
                      id="region"
                      label="Region"
                      name="region"
                      required
                      value={values?.region}
                      onChange={handleChange}
                      error={errors?.region}
                      data-gramm="false"
                      placeholder="Enter region"
                    />
                    <InputField
                      id="access_key"
                      label="Access Key"
                      name="access_key"
                      required
                      value={values?.access_key}
                      onChange={handleChange}
                      error={errors?.access_key}
                      data-gramm="false"
                      placeholder="Enter access key"
                    />
                    <InputField
                      id="secret_key"
                      label="Secret Key"
                      name="secret_key"
                      required
                      value={values?.secret_key}
                      onChange={handleChange}
                      error={errors?.secret_key}
                      data-gramm="false"
                      placeholder="Enter secret key"
                    />
                  </Card>
                  <CustomButton isLoading={isSubmitting} type="submit" w="full" colorScheme="brand">
                    Create
                  </CustomButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Grid>
  );
}
